import MDinput from '@/components/MDinput';
import Select from '@/components/DSE/Select';
import { validatorRequire } from '@/utils/validators';
import i18n from '@/i18n';

const validateNewPassword = vue => (rule, value, callback) => {
  if (value !== '' && value != null) {
    const isValid = vue.passwordCompliesWithPolicy(value);
    if (Object.values(isValid).some(e => !e)) {
      callback(new Error(i18n.t('validation.error.passwordComplexity')));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export function getFormDefinition(vue) {
  return {
    username: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 32,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    password: {
      type: MDinput,
      defaultValue: '',
      props: {
        type: 'password',
        maxlength: 64
      },
      dynamicProps: form => ({
        caption: vue.isEdit ? 'user.newPassword' : 'common.password'
      }),
      dynamicRules: form => {
        const rules = [{ validator: validateNewPassword(vue) }];
        if (!vue.isEdit) {
          rules.push({ validator: validatorRequire });
        }
        return rules;
      }
    },
    domainID: {
      type: Select,
      defaultValue: vue.$store.getters['domain/getDefaultSelectValueDomainID'],
      props: {
        caption: 'common.domain',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['domain/isLoading'],
        options: vue.$store.getters['domain/loadedItems']
      },
      dynamicProps: form => ({
        visible: true
      }),
      valueOnFormChange: (form, oldValue) => {
        return oldValue;
      }
    }
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
